import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDIlPjnwRWddVymktCkTV2RBulg3UCxBVE",
  authDomain: "controlroom-live.firebaseapp.com",
  databaseURL: "https://controlroom-live.firebaseio.com",
  projectId: "controlroom-live",
  storageBucket: "controlroom-live.appspot.com",
  messagingSenderId: "201157728453",
  appId: "1:201157728453:web:aa61ccf68646d3a3",
  measurementId: "G-WNN4S7697M",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default db;

export const firestoreConverter = {
  toFirestore(document) {
    const { id, ...data } = document;
    return data;
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    return { ...data, id: snapshot.id };
  },
};
