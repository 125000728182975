import React from "react";
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import { collection, doc, query, where } from "firebase/firestore";
import db, { firestoreConverter } from "./firebase"; // adjust path as needed
import Product from "./Product";
import "./App.css";
import { motion, AnimatePresence } from "framer-motion";

const App = () => {
  const productsRef = collection(db, "products");
  const q = query(
    productsRef,
    where("visible", "==", true),
    where("unlocked", "==", true)
  );
  const [products, loading, error] = useCollectionData(
    q.withConverter(firestoreConverter)
  );
  const [settings, settingsLoading, settingsError] = useDocumentData(
    doc(db, "products", "settings")
  );

  console.log({
    products,
    loading,
    error,
    settings,
    settingsLoading,
    settingsError,
  });

  if (settingsLoading || settingsError) {
    return null;
  }

  const list1 = settings?.list1
    ?.map((id) => products.find((product) => product.id === id))
    .filter(Boolean); // remove any undefined items
  const list2 = settings?.list2
    ?.map(
      (id) =>
        products.find((product) => product.id === id) || {
          id,
          productName: "A Really Great Deal",
          image: `/assets/shadow.svg`,
          unlocked: false,
          expired: false,
          couponCode: "FAKECODE",
          externalUrl: "https://www.amazon.com",
        }
    )
    .filter(Boolean); // remove any undefined items

  return (
    <div className={`container`}>
      <div>
        {loading && <p>Loading...</p>}
        {error && <p>Error: {error.message}</p>}
        <header>
          <div
            className="logo"
            aria-label="Prime Night: Duel for the Deals"
          ></div>
        </header>
        <AnimatePresence>
          <a
            className="promo renew-promo"
            href="https://www.amazon.com/joinprime?ref=xcm_social_cn_ppd23"
          >
            <h3>Every Prime Day Deal, One Membership</h3>
            <button className="button">Join Prime</button>
          </a>

          {list1?.length > 0 && (
            <>
              <h2 className="banner">Unlocked</h2>
            </>
          )}
          <div className="products">
            {list1?.map((product) => (
              <motion.div layout key={product.id}>
                {product.productName === "VIDEOPROMO" ? (
                  <a
                    className="promo video-promo"
                    href="https://www.amazon.com/s?k=B0B6QPS7T1%7CB0B67698SP%7CB0B8TTP88S%7CB0B6R7M5KC%7CB0B8JDQ2X1&ref=xcm_social_cn_ppd23"
                  >
                    <h3>What's next on the watch list?</h3>
                    <p>
                      Choose from top Prime Video movies to rent or buy up to
                      50% off
                    </p>
                    <button className="button">Join Prime</button>
                  </a>
                ) : (
                  <Product key={product.id} product={product} size="large" />
                )}
              </motion.div>
            ))}
          </div>

          {list2?.length > 0 && (
            <>
              <h2 className="banner">Upcoming Exclusive Deals</h2>
            </>
          )}
          <div className="products">
            {list2?.map((product, i) => (
              <>
                <motion.div layout exit={{ scale: 0 }} key={product.id}>
                  <Product key={product.id} product={product} size="small" />
                </motion.div>
              </>
            ))}
          </div>
        </AnimatePresence>
      </div>
      <footer>
        <p>
          Viewers of @prime’s TikTok Live may claim available discount codes
          through this webpage. Codes will only be made available as determined
          by @prime in its sole discretion. Codes are not guaranteed.
        </p>
        <p>
          Each code is valid for a discount on{" "}
          <a href="https://www.amazon.com" target="_blank" rel="noreferrer">
            Amazon.com
          </a>
          . Discount codes are valid for one or more products and subject to
          that product’s availability. Codes are available only through provided
          links in @prime’s TikTok Live and while supplies last. All codes must
          be claimed by July 12 at 11:59pm ET. Discount codes may not be
          combined with any other discount or offer other than the Prime Day
          Deal on that particular product. Codes expire 24 hours after
          acceptance. Use of codes subject to{" "}
          <a
            href="https://www.amazon.com/gp/help/customer/display.html?nodeId=GLSBYFE9MGKKQXXM"
            target="_blank"
            rel="noreferrer"
          >
            Amazon.com Conditions of Use
          </a>
          . Offers may vary and only available while supplies last. Restrictions
          apply.
        </p>
      </footer>
    </div>
  );
};

export default App;
