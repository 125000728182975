import React from "react";
import CouponComponent from "./CouponComponent";

const Product = ({ product, size = "large" }) => {
  return (
    <div
      className={`product product-${size} ${
        product.expired ? "expired" : "in-stock"
      } ${product.unlocked ? "unlocked" : "locked"} `}
    >
      <div className="product-name-wrapper">
        <h2 className="product-name">{product.productName}</h2>
        {product.discount ? (
          <div className="product-discount">{product.discount} off</div>
        ) : null}
      </div>
      <div className="product-thumbnail-wrapper">
        <img
          className="thumbnail"
          src={product.image}
          alt={product.productName}
        />
      </div>
      <div className="product-details-wrapper">
        {/* <p>ASIN Code: {product.asinCode}</p> */}
        {/* <div className="price">${product.price}</div> */}
      </div>
      <div className="product-button-wrapper">
        {product.couponCode && !product.expired && (
          <>
            <div className="coupon-instructions">
              1. Copy the coupon code to use during checkout
            </div>
            <CouponComponent code={product.couponCode} />
          </>
        )}

        {product.expired ? (
          <a href={product.externalUrl} className="button">
            Deal expired
          </a>
        ) : (
          <a href={product.externalUrl} className="button">
            2. Shop This Deal
          </a>
        )}
      </div>
    </div>
  );
};

export default Product;
