import React, { useState, useRef } from "react";

const CouponComponent = ({ code }) => {
  const [copyState, setCopyState] = useState(""); // change to hold 3 states - "", "show", "hide"
  const textAreaRef = useRef(null);

  const copyToClipboard = (e) => {
    navigator.clipboard.writeText(code).then(
      function () {
        /* clipboard successfully set */
        setCopyState("show");
        setTimeout(() => setCopyState("hide"), 2000);
      },
      function () {
        /* clipboard write failed */
        console.error("Failed to copy the code to clipboard.");
      }
    );
  };

  return (
    <div className="coupon-component" onClick={copyToClipboard}>
      <div className="coupon-code" ref={textAreaRef}>
        {code}
      </div>
      <button>Copy code</button>
      <div className={`confirmation-message ${copyState}`}>Copied!</div>
    </div>
  );
};

export default CouponComponent;
